import React , { useEffect, useState }  from 'react';

import {Button, Card, Col, Collapse, Row, Statistic} from 'antd';

import moment from "moment";
import Axios from '../Axios';
import { Session } from "bc-react-session";
import ReportTable from './Dashboard/ReporTable';
const { Panel } = Collapse;

const Dashboard = () => {

  const [reportStatus, setReportStatus] = useState([]);
  const periodAndroid  = moment().add(-3,'day');
  const periodIos  = moment().format('D') < 5 ? moment().add(-2,'month') :  moment().add(-1,'month') ;

  const session = Session.get();

  console.log({session})

  const [isLoading, setIsLoading] = useState(0);

  const [stats, setStats] = useState({
    android: {
      total_downloads: 0,
      active_installs: 0,
    },
    ios: {
      total_downloads: 0,
      current_month: 0,
    },
  });

  const getDownloads = async () => {
    setIsLoading(true);
    try {
      const res =  await Axios.get("/analytics/downloads",{params: {date: periodAndroid.format('YYYY-MM-DD'), country: session.payload.authority.country}});
      setStats(res.data)
    } catch (error) {
    }

   setIsLoading(false);
  }

  const getReporBySatatus = async () => {
    setIsLoading(true);
    try {
      const res =  await Axios.get("/analytics/report-status-period");
      setReportStatus(res.data)
    } catch (error) {
    }
   setIsLoading(false);
  }

  useEffect(()=> {
    getDownloads();
    getReporBySatatus();
  }, [])

 return <><Collapse >
      <Panel header="Información importante" key="1" >
      ¡Bienvenido(a) a VIPA! Cada día trabajamos para hacer que esta herramienta logre que nuestras ciudades sean mejores. Al utilizarla, tienes el compromiso de no compartir tu usuario, ni de compartir vistas/funcionalidades con personas fuera de tu organización. Es tu responsabilidad proteger nuestra propiedad intelectual y esperamos tengan un uso responsable de ella.
      Any queries are available!<br/>
    El equipo VIPA
      </Panel>

      </Collapse>
      
 { session.payload.authority.country == 'EC' &&    <Row gutter={16} style={{marginTop: 20}}>
      <Col xs={24} sm={12}>
        <Card loading={isLoading} title="Android">
          <Statistic title={`Instalaciones en ${periodAndroid.format('MMMM')}`} value={stats.android.current_month} />
          <Statistic title={`Instalaciones activas hasta ${moment().format('MMMM YYYY')}`} value={stats.android.total_downloads} style={{ marginTop: 16 }} />
        </Card>
      </Col>
      <Col xs={24} sm={12}>
        <Card loading={isLoading}  title="iOS">

          <Statistic title={`Instalaciones en ${moment().format('MMMM')}`} value={stats.ios.current_month} style={{ marginTop: 16 }} />
          <Statistic title={`Instalaciones activas hasta ${moment().format('MMMM YYYY')}`} value={stats.ios.total_downloads} />
        </Card>
      </Col>
    </Row>}

    <ReportTable reportStatus={reportStatus}/>
  
      </>

}

export default Dashboard;
