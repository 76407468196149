import React from "react";
import { Table, Card, Tag, Tooltip } from "antd";
import { ArrowUpOutlined, ArrowDownOutlined } from "@ant-design/icons";

const ReportTable = ({ reportStatus }) => {
  const columns = [
    {
      title: "Periodo",
      dataIndex: "period",
      key: "period",
      render: (text) => <strong>{text}</strong>,
    },
    {
      title: "Multados",
      dataIndex: "penalized",
      key: "penalized",
      render: (count, record) => (
        <div style={{ display: "flex", alignItems: "center", gap: 4 }}>
          <h1>{count ?? 0}</h1>
         
          <Tooltip title={`Versus ${record.penalized_previous_growth} registros, respecto al mismo día y hora del periodo anterior`}>
          {shouldShowGrowth(record.key) && renderGrowth(record.penalizedGrowth)} 
          </Tooltip>
     
        </div>
      ),
    },
    {
      title: "No Multados",
      dataIndex: "attended",
      key: "attended",
      render: (count, record) => (
        <div style={{ display: "flex", alignItems: "center", gap: 4 }}>
          <h1>{count ?? 0}</h1>
          {shouldShowGrowth(record.key) && renderGrowth(record.attendedGrowth)}
        </div>
      ),
    },
  ];

  const dataSource = Object.keys(reportStatus).map((period) => ({
    key: period,
    period: formatPeriod(period),
    penalized: reportStatus[period]?.penalized ?? 0,
    attended: reportStatus[period]?.attended ?? 0,
    penalizedGrowth: reportStatus[period]?.penalized_growth,
    attendedGrowth: reportStatus[period]?.attended_growth,
    penalized_previous_growth: reportStatus[period]?.penalized_previous_growth,
    attended_previous_growth: reportStatus[period]?.attended_previous_growth,
  }));

  function formatPeriod(period) {
    const mapping = {
      today: "Hoy",
      yesterday: "Ayer",
      this_week: "Esta Semana",
      last_week: "Semana Anterior",
      this_month: "Este Mes",
      last_month: "Mes Anterior",
    };
    return mapping[period] || period;
  }

  function shouldShowGrowth(periodKey) {
    return ["today", "this_week", "this_month"].includes(periodKey);
  }

  function renderGrowth(value) {
    if (value === null || value === undefined) return null;
    return (
      <Tag color={value >= 0 ? "green" : "red"} style={{ minWidth: 50, textAlign: "center" }}>
        {value}% {value >= 0 ? <ArrowUpOutlined /> : <ArrowDownOutlined />}
      </Tag>
    );
  }

  return (
    <Card title="Reporte de Multas" bordered={false} style={{ width: "100%" }}>
      <Table columns={columns} dataSource={dataSource} pagination={false} bordered />
    </Card>
  );
};

export default ReportTable;
